import pic0 from "./img/VerbMe_mockup06.gif";
import pic1 from "./img/VerbMe_mockup03.jpg";
import pic2 from "./img/VerbMe_mockup04.jpg";
import pic3 from "./img/VerbMe_mockup07.jpg";
import pic4 from "./img/VerbMe_mockup08.jpg";
import pic5 from "./img/VerbMe_mockup09.jpg";
import pic6 from "./img/VerbMe_mockup10.jpg";
import pic7 from "./img/VerbMe_mockup11.jpg";
import pic8 from "./img/VerbMe_mockup12.jpg";

const linkDesc = [
    {   
        link: "https://www.irregular-verbs.net/",
        gitHub: "https://github.com/Wojciach/irregular-verbs.git",
        pic: pic1, 
        techUsed: "JavaScript / HTML / CSS / mySql / PHP / Google Analytics",
        desc: "As my inaugural web development project, I chose to employ fundamental tools including JavaScript, HTML, CSS, mySql, and PHP. This endeavor served as a valuable test of my coding abilities, honed over the past two years. My goal was to see the project through from start to finish, encountering and addressing any challenges that arose during the development process. By doing so, I aimed to gain insight into which aspects proved most problematic, allowing me to avoid similar issues in future projects."
    },
    {
        link: "",
        gitHub: "https://github.com/Wojciach/wyrwoj.website.git",
        pic: pic2, 
        techUsed: "React.js / SASS / HTML / mySql / Google Analytics",
        desc: "This digital CV that you're currently viewing is actually my second project. Through my first project, I came to the realization that in order to write cleaner and more maintainable code, I needed to incorporate some JavaScript and CSS frameworks. After exploring Angular and Vue, I ultimately decided to work with ReactJS for this particular project. Additionally, SASS proved to be a major game changer and I doubt that I will ever go back to writing pure CSS again. As for the backend and database support, I utilized PHP and mySQL, but I have experimented with NodeJS and may consider using it in the future. Unfortunately, due to the limitations of my current hosting provider, I am unable to install NodeJS on their shared hosting service option. While I have set up my own server for testing, I am not yet prepared to deal with any reliability issues at my current stage of personal development. Therefore, I have made the decision to delay the use of NodeJS for backend development at this time."
    },
    {
        link: "https://recta.website/",
        gitHub: "https://github.com/Wojciach/recta.git",
        pic: pic3, 
        techUsed: "React.JS / SASS / HTML / mySql / PHP",
        desc: "I recently completed a construction company website using React, Sass, HTML, MySQL, and PHP. This project allowed me to refine my React skills, correct coding habits, and improve the integration between PHP back-end and React front-end. Focusing on OOP in PHP made the server-side more versatile for potential use in future projects. The result is a polished and scalable website, demonstrating my commitment to continuous improvement and technical excellence."
    },
    {
        link: "https://polbiz.net/",
        gitHub: "https://github.com/Wojciach/vue_polbiz.git",
        pic: pic6, 
        techUsed: "Vue.js / Nuxt.js / Tailwind CSS / GSAP / HTML",
        desc: "Creating this project was an enlightening experience that deepened my understanding of Vue.js and Nuxt 3. I particularly enjoyed exploring the nuances of Nuxt 3's composition API and its integration with Vue 3. The process of implementing GSAP for animations was both challenging and rewarding, opening up new possibilities for enhancing user experience in future projects."
    },
    {
        link: "https://tomix.polbiz.net/",
        gitHub: "https://github.com/Wojciach/vue_tomix.git",
        pic: pic7, 
        techUsed: "Vue.js / Nuxt.js / Tailwind CSS / HTML",
        desc: "This Vue.js and Nuxt 3 project showcases a company specializing in building elevation renovations. Features include an interactive before-and-after gallery, animated service descriptions, and a quote request form with real-time cost estimation. Developing this site honed my skills in creating performance-optimized, visually appealing business websites. I implemented lazy-loading for images and custom animations to highlight renovation processes. This project demonstrated my ability to translate client needs into effective web solutions, balancing attractive design with functionality to drive user engagement and conversions."
    },
    {
        link: "https://emiston.polbiz.net/",
        gitHub: "https://github.com/Wojciach/vue_emiston.git",
        pic: pic8, 
        techUsed: "Vue.js / Nuxt.js / Tailwind CSS / HTML",
        desc: "This Nuxt 3 project showcases a professional website for a road and pavement construction company. Key features include an interactive map of completed projects and detailed service descriptions with visual aids. The site's structure leverages reusable code components, originally developed for the Tomix webpage, demonstrating my ability to create efficient, scalable solutions. While both sites share a similar foundation, this project focuses on showcasing the company's expertise in road infrastructure. The main difference is that the Tomix site includes a cost estimation calculator, which was not required for this project. Developing this site reinforced my skills in creating modular, reusable code within the Nuxt 3 framework, allowing for faster development and easier maintenance across multiple projects."
    },
    {
        link: "https://owl.wyrwoj.website/",
        gitHub: "https://github.com/Wojciach/PhotoGallery.git",
        pic: pic0, 
        techUsed: "JavaScript / SASS / HTML / mySql / PHP",
        desc: "I have created a simple website to showcase a sample of my photo and video portfolio, featuring work done for the leather carving studio Bulfinch Workshop. I'm fully aware that the current state of this site is subpar and needs significant improvement. This is very much a temporary, work-in-progress project. I acknowledge its shortcomings and am committed to overhauling it in the future to better represent my skills and the quality of my work. The site will undergo substantial changes and upgrades to enhance both its aesthetics and functionality"
    },
    {
        link: "https://gymqr.make-me.website/index.html",
        gitHub: "https://github.com/Wojciach/gymqr.git",
        pic: pic4, 
        techUsed: "JavaScript / SASS / HTML / mySql / PHP / JQuery",
        desc: "Currently developing a system for efficient gym membership management. Using PHP, JavaScript, mySql, Sass, and jQuery, the project includes QR code generation and scanning, user/coach interfaces, and emphasizes modular JavaScript for scalability. Refreshing jQuery skills, focusing on PHP OOP for a structured server-side. Streamlining processes for a seamless user experience.",
        desc2: "I recognize that this project requires substantial improvement in terms of graphic design, and I plan to address these visual shortcomings in future updates to better showcase my work. I decided to publish it to provide you with insight into what I am currently working on. The project is still under development, but it works and is functional. It serving as an MVP for testing purposes. Please refer to the tutorial for more information on how to use it. At this moment, the app allows you to log in, add gym members, generate and send QR codes to members, scan those codes to monitor attendance, and send emails with reminders about payments, among other functionalities."
    }

]

export default linkDesc;