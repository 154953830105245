import "./footer.scss"
function Footer() {
//console.log("Footer");
        return(
            <footer id="footer">
                <div className="fake-margin">Curriculum Vitae</div> 
            </footer>
            )
}

export default Footer;